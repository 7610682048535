.profilename_heading {
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}

#form-main-container {
  display: block;
  position: relative;
  border-radius: 20px;
  background: rgba(54, 69, 79, 0.8);
  box-shadow: var(--login-card-box-shadow);
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  padding: 1%;
}

#form-area {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 50px;
}

#form-body {
  margin-top: 12px;
}

#form-area {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 50px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da;
}

.form-control::placeholder {
  color: #c8c8c8 !important;
}

a {
  text-decoration: none !important;
  color: unset !important;
}

a:hover {
  color: #008080 !important;
}

.delete_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 32px 10px 32px;
  gap: 8px;
  height: 40px;
  background: red;
  border-radius: 5px;
  border: 1px solid red;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}
.delete_button:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 32px 10px 32px;
  gap: 8px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid red;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: red;
}

.download_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 15px;
  gap: 8px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #008080;
}
.download_btn:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  padding: 10px 15px 10px 15px;
  gap: 8px;
  /* width: 200px; */
  height: 40px;
  background: #008080;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}

.signup_button:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 15px;
  gap: 8px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #008080;
}
.signup_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 32px 10px 32px;
  gap: 8px;
  height: 40px;
  background: #008080;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}

.signup_button:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  padding: 10px 32px 10px 32px;
  gap: 8px;
  /* width: 200px; */
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #008080;
}

body {
  background-color: #ededed !important;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .dashboard-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.profile-dropdown {
  z-index: 9999 !important;
}

.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.profile_name_heading {
  color: #3c3c3c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.scroll-button {
  display: none;
  /* Initially hidden */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

header {
  z-index: 99 !important;
}

.modal-footer {
  border-top: none !important;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.img,
.profile-description {
  color: #8498ab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.profile_hr_tag {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

.userprofile-dropdown {
  margin-bottom: -10px !important;
}

@media (min-width: 320px) and (max-width: 1070px) {
  .chips_section {
    flex-wrap: wrap;
  }
  .chip {
    margin-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .login-image {
    display: none;
  }
  .addemployee_text {
    font-size: 11px;
  }
  .add_button {
    width: 12px;
  }
  .banner-section {
    margin: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px !important;
  }
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  /* padding-left: 250px; */
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3c4c57;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.logo {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

#wrapper.toggled #sidebar-wrapper {
  width: 310px; /* 310px is 31% of the parent container's width */
  transition: all 0.5s ease;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding-top: 70px;
  /* padding: 15px; */
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.sidebar-nav {
  /* position: absolute;
  top: 0; */
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.accordion-body {
  padding: 0px !important;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
  margin: 10px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  align-items: center;
}

.sidebar-nav > .sidebar-brand {
  /* height: 65px; */
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 340px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    align-items: center;
}
  body {
    overflow: auto !important;
  }

  .headingfour {
    text-align: left;
    font-size: 23px;
    font-weight: 500;
    font-style: normal;
    color: #736f6f;
  }

  .logo {
    margin-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width: 920px) {
  .footer {
    background-color: #f8f9fa;
    /* padding: 10px 0; */
    text-align: start;
    /* position: sticky; */
    bottom: 0;
    width: -webkit-fill-available !important;
    box-sizing: border-box;
    margin: 0;
  }
}

@media (min-width: 768px) {
  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 340px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    /* padding: 20px; */
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

html {
  scroll-behavior: smooth;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgba(115, 111, 111, 1) !important;
  border-radius: 0px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #008080 !important;
  background-color: unset !important;
  border-bottom: 3px solid #008080 !important;
  font-weight: 600 !important;
  /* text-align: center !important; */
  border-radius: 0px !important;
}

.nav-pills .nav-link {
  border-radius: 0px !important;
}

.nav-justified .nav-item .nav-link {
  background: none !important;
  border-radius: 0px !important;
  font-weight: 600 !important;
  /* color: rgba(115, 111, 111, 1) !important; */
}

.nav-link {
  color: rgba(115, 111, 111, 1) !important;
  /* margin-bottom: 10px !important; */
  font-weight: 600 !important;
}

.banner-section-dashboard {
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(173, 173, 173, 0.25);
  border-radius: 10px;
  padding: 20px;
  /* height: 85vh; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

@media (min-width: 320px) and (max-width: 768px) {
  .banner-section-dashboard {
    background: #ffffff;
    box-shadow: 2px 2px 20px rgba(173, 173, 173, 0.25);
    border-radius: 10px;
    padding: 20px;
    /* height: 100vh */
  }

  .dashboard-cards {
    text-align: center;
  }

  .navbar-brand {
    padding-left: 0px;
  }

  .main-navbar {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0px !important;
    z-index: 1030;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .button_lable {
    font-size: 12px;
  }
  .add_employee_btn span {
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .employee_buttons {
    flex-wrap: wrap;
    display: block !important;
  }
  .responsive_header_button {
    flex-wrap: wrap;
  }
  .tabs {
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .inventorylist-paragraph {
    width: 100%;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    /* height: 250px; */
    padding: 15px;
    /* left: 0px; */
    top: 36px;
    min-width: 270px;
    border-radius: 4px;
    box-shadow: 2px 2px 16px 0px rgba(39, 39, 39, 0.1);
    z-index: 1;
    text-align: left;
  }

  .inventorylist_button_group {
    display: flex;
    justify-content: flex-start !important;
  }
}

.dropdown-item {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  padding: 15px;
  right: 0px;
  top: 36px;
  min-width: 270px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(173, 173, 173, 0.25);
  border-radius: 8px;
  color: #000;
  z-index: 1;
  text-align: left;
}

.dropdown-content a {
  text-decoration: none;
  color: #000;
  display: block;
  text-align: var(--text-left);
  padding-bottom: 15px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  /* background-color: #F1F1F1 !important; */
  border: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  padding: 15px !important;
  color: #736f6f !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* color: #fff !important; */
  font-weight: 600 !important;
  color: #db5a04 !important;
  border-bottom: 3px solid #db5a04 !important;
}

@media (min-width: 320px) and (max-width: 425px) {
  .nav-tabs {
    flex-direction: column !important;
  }
}

.layout .footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px;
}

.sidebar {
  color: #7d84ab;
  overflow-x: hidden !important;
  position: relative;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #1a4173;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #0c1e35;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #6c757d;
}

.badge.primary {
  background-color: #ab2dff;
}

.badge.secondary {
  background-color: #079b0b;
}

.sidebar-toggler {
  position: fixed;
  right: 20px;
  top: 20px;
}

.social-links a {
  margin: 0 10px;
  color: #3f4750;
}

.nav-link:hover {
  transition: all 0.4s;
}

.nav-link-collapse:after {
  float: right;
  content: "\f067";
  font-family: "FontAwesome";
}

.nav-link-show:after {
  float: right;
  content: "\f068";
  font-family: "FontAwesome";
}

.nav-item ul.nav-second-level {
  padding-left: 0;
}

.nav-item ul.nav-second-level > .nav-item {
  padding-left: 20px;
}

.copyright-section {
  background: #fff;
  border: 1px !important;
  box-shadow: 2px 2px 20px hsla(0, 0%, 68%, 0.5) !important;
}

.addgoals_tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  border-bottom: 4px solid #db5a04 !important;
  border-radius: 0px !important;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: unset !important;
}

.accordion {
  --bs-accordion-border-color: none !important;
}

.collapsing {
  height: 0px !important;
}

.cashback {
  padding: 110px 0px 0px 10px !important;
}

.footer_link {
  font-size: 14px;
  font-weight: 500;
  color: #3c4c57;
  font-style: normal;
  text-decoration: underline;
}

.copyright-heading {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.table_section .table {
  border: 1px solid gainsboro;
  margin-bottom: 0px !important;
}

.table_section .table th {
  background-color: #cdcdcd;
  padding: 10px;
  height: 36px;
}

.table_section .table td {
  color: #000;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  padding: 10px;
  height: 36px;
}

.add_employee_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  /* width: 150px; */
  height: 40px;
  background: #008080;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}

.add_employee_btn:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  gap: 8px;
  /* width: 102px; */
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #008080;
}

.cross_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  /* width: 102px; */
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #db2222;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #db2222;
}

.cross_btn:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  /* width: 150px; */
  height: 40px;
  background: #db2222;
  border-radius: 5px;
  border: 1px solid #db2222;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}

.outline_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  padding: 10px 32px 10px 32px;
  gap: 8px;
  /* width: 102px; */
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #008080;
}

.outline_btn:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  padding: 10px 32px 10px 32px;
  gap: 8px;
  /* width: 150px; */
  height: 40px;
  background: #008080;
  border-radius: 5px;
  border: 1px solid #008080;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: 0.3s;
  color: #fff;
}

.edit_icon {
  cursor: pointer;
}

.delete_icon {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
  color: #717070;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #008080 !important;
  border: #008080 !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
}

.inputfield.form-control {
  background-color: #eeeeee;
  border: #eeeeee;
  color: #636363 !important;
  font-weight: 500 !important;
  /* height: 38px !important; */
}

.inputfield.form-control:focus {
  background-color: #eeeeee;
  border: gray;
  color: #000;
}

.inputfield.form-control::placeholder {
  color: #636363 !important;
  font-weight: 400 !important;
}

.inputfield.form-select {
  background-color: #eeeeee;
  color: #000;
  border: #eeeeee;
  /* cursor: pointer; */
  /* height: 38px !important; */
}
.form-select.option {
  cursor: pointer !important;
}

.inputfield.form-select:focus {
  background-color: #eeeeee;
  border: gray;
}

.inputfield.form-select::placeholder {
  color: #000 !important;
}

.login_formcontrol.form-control {
  background-color: #515a63;
  border: 1px solid #8f8f8f;
  height: 40px;
  color: #ffff;
}

.login_formcontrol.form-control:focus {
  background-color: #515a63;
  border: #515a63;
  height: 40px;
  color: #ffff;
}

.login_formcontrol input.login-input::placeholder {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.banner-section-dashboard {
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #f8f9fa;
  /* padding: 10px 0; */
  text-align: start;
  /* position: sticky; */
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.banner-section {
  margin: 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: fixed !important;
  bottom: 0;
  z-index: 1020;
  margin-right: 0 !important;
  /* Ensure no right margin */
}
.scroll-to-top-button {
  z-index: 99999;
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: #008080;
  color: #fff;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(20px);
}

.scroll-to-top-button.visible {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-item span {
  font-weight: 500;
  font-size: 15px;
  font-style: normal;
  line-height: 20px;
}

.custom_header.dropdown-menu.show {
  display: block;
  border: none !important;
  border-radius: 5px !important;
  /* padding: 0px !important; */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2) !important;
  opacity: 0; /* Start with 0 opacity */
  animation: fadeIn 0.35s ease-out forwards; /* Apply animation */
  transition: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  text-decoration: none;
  background-color: gainsboro !important;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: none !important;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropdowns {
  position: relative !important;
  z-index: 1000 !important;
}

.dropdown-menu {
  position: absolute !important;
  /* top: 100%; */
  /* left: 0; */
  right: 3%;
  display: "block";
  transition: 0.35s ease-out;
}
.accordion-button::after {
  background-image: url("../src/assets/img/downarrow.svg") !important;
  color: #fff !important;
}

.accordion-button:not(.collapsed)::before {
  background-image: url("../src/assets/img/downarrow.svg") !important;
  transform: var(--bs-accordion-btn-icon-transform);
  background-size: 15px !important;
}

.accordion-button::after {
  background-image: url("../src/assets/img/downarrow.svg") !important;
}

.table > thead {
  vertical-align: middle !important;
}

.sidebar-nav .nav-link.active {
  color: #ffffff;
  /* background-color: #007bff; */
  background: #586f80;
  border-radius: 10px;
  transition: margin 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    padding 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.active-item {
  background-color: #000;
}

.nav-link {
  border-radius: 10px !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 4;
  /* font-weight: bold !important; */
  font-style: normal;
}

.header_heading {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.icon {
  color: gray !important;
}
.tab-pane {
  transition: none !important;
}
.nav-tabs .nav-link {
  transition: none !important;
}

.tab-pane {
  opacity: 1 !important;
  visibility: visible !important;
}

.logout_link a {
  color: red !important;
}
.projects_dropdownlinks {
  color: #008080;
}

.cashback_card {
  border: 2px solid #006868;
  background-color: #fff;
  box-shadow: 2px 2px 20px hsla(0, 0%, 68%, 0.5);
}
.card_text {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  /* line-height: 18.75px; */
  font-style: normal;
  color: #006868;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.login_section {
  display: flex;
  flex-direction: column; /* Updated to column layout */
  justify-content: center;
  align-items: center;
}

.error-container {
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.error-head {
  margin-bottom: 0;
}

.error-divider {
  width: 3px;
  height: 3em;
  background-color: #fff;
  display: inline-block;
  margin: 0 20px;
}

.error-message {
  margin-top: 0;
}

@media (max-width: 768px) {
  .error-container {
    text-align: center;
    color: #fff;
    display: block;
  }
  .error-divider {
    display: none;
  }
}

table td {
  vertical-align: middle;
}

.form-check-input:checked {
  background-color: #008080 !important;
  border-color: #008080 !important;
}

.dropdown-menu.show {
  display: block;
  border: none !important;
  border-radius: 5px !important;
  /* padding: 0px !important; */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2) !important;
  /* opacity: 0; */
  transition: 0.35s ease-out;
  z-index: 99999 !important;
}

.login_label_text {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 17px;
}

@media (min-width: 768px) {
  .button_lable {
    display: block;
    /* font-size: 12px; */
  }
  .tooltip_buttons {
    display: none;
  }
}

@media (max-width: 900px) {
  .button_lable {
    display: none;
  }
  .tooltip_buttons {
    display: block;
  }
  .download_btn {
    padding: 8px;
    gap: 8px;
    height: 30px;
  }
  .download_btn:hover {
    padding: 8px;
    gap: 8px;
    height: 30px;
  }
  .custom-modal-xl {
    max-width: 100%;
  }
}

.modal-header h1 {
  font-size: 24px !important;
}

.forgot_password_text {
  font-size: 14px;
  color: #c7d4de;
  font-weight: 500;
}

.sales_handover {
  color: #736f6f;
  font-weight: 600;
  font-size: 16px;
}

.view_header {
  text-align: center;
  color: #fff;
}

.tabs {
  display: flex;
  width: 200px;
  border: 1px solid #ddd;
  background-color: rgba(237, 237, 237, 1);
  color: rgba(87, 98, 108, 1);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.tabs > .line {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #ddd;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.tabs > .tab {
  flex: 1;
  display: flex;
}
.tab > .tab-input {
  width: 0;
  height: 0;
  margin: 0;
  display: none;
  color: #000;
}
.tab > .tab-box {
  padding: 0.4rem;
  width: 100%;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
}
.tab > .tab-input:checked + .tab-box {
  background: rgba(0, 128, 128, 1);
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.list-items {
  position: relative;
}
.edit_icon_profile {
  position: absolute;
  left: 35px;
  top: 30px;
  color: rgba(0, 128, 128, 1);
  border: 1px solid #fff;
  /* border-radius: 50%; */
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
  display: block;
  justify-content: center;
  align-items: center;
}
.asterisk_text {
  color: #ff0000;
  font-size: 18px;
}

.invalidtoken_img {
  height: 50px;
  width: 50px;
}

.preview_icons {
  cursor: pointer;
}
.cursor_disabled {
  cursor: not-allowed;
}
.table_text_overflow {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e8e8e8;
  border: none;
  cursor: default;
  height: 32px;
  outline: none;
  padding: 0;
  font-size: 14px;
  margin: 0px;
  color: #36454f;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #32c5d2;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 36px;
  color: #fff;
  height: 36px;
  font-size: 20px;
  margin-right: -4px;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  color: #36454f;
  font-weight: 600;
}
.chip-svg {
  color: #999999;
  cursor: pointer;
  height: auto;
  margin: 0px 4px 0 -8px;
  fill: currentColor;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  margin-bottom: 2px;
}
.chip-svg:hover {
  color: #666666;
}

.preview_icons {
  background-color: rgba(88, 111, 128, 1);
  border-radius: 50%;
  border-radius: 36px !important;
  font-size: 24px !important;
  height: 33px;
  width: 33px;
  color: #fff;
  border: 2px solid rgba(88, 111, 128, 1);
}


.preview_icons:hover {
  background-color: #fff;
  border-radius: 50%;
  border-radius: 36px !important;
  font-size: 24px !important;
  height: 33px;
  width: 33px;
  color: rgba(88, 111, 128, 1);
  border: 2px solid rgba(88, 111, 128, 1);
}

.projects_heading {
  font-size: 28px;
  color: #646464;
  font-style: normal;
  font-weight: 600;
}
.customer_dashboard_card.card {
  background-color: #fff;
  border: none;
  box-shadow: 0px 4px 25px 0px #00000026;

  border-radius: 16px;
}

.customer_card_paragraph {
  font-size: 16px;
  font-weight: 400;
  color: #676767;
  margin-bottom: 5px;
  word-break: break-all;
  line-height: 22px;
}

.token_recived_text {
  color: #5f6fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
}

.response_text {
  display: none;
}
.response_text_heading {
  display: block;
}

@media (min-width: 320px) and (max-width: 470px) {
  .response_text {
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 470px) {
  .response_text {
    display: block;
  }
  .response_text_heading {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 470px) {
  .response_text {
    display: block;
  }
}
@media (min-width: 0px) and (max-width: 320px) {
  .response_text_heading {
    display: none;
  }
  .response_text {
    display: block;
  }
}
.disabled-label {
  color: #9b9b9b;
}


/* .fade{
  display: block;
  padding-left: 10px;
  z-index: 99999 !important;
} */
.sticky-top{
  z-index: 9 !important;
}


/* .disabled-label {
  color: gray; 
} */


.breadcrumb_main_heading{
  font-weight: 600;
  font-size: 32px;
  color: #736F6F;
}
ul li{
  list-style-type: none;
}
ul{
  padding: 0px;
  margin: 0px;
}
.breadcrumb_paragraph{
  font-size: 14px;
  font-weight: 600;
  color:#736F6F;
  line-height: 12px;
}
.breadcrumb_description{
  font-size: 14px;
  color: #000000;
  word-wrap: break-word;
  display: block;
  font-weight: 500;
}
.breadcrumb_description span{
  font-size: 14px;
  color: #000000;
  word-wrap: break-word;
  display: block;
  font-weight: 500;
}
/* .breadcrumb_description_names{
  display: block;
  word-wrap: break-word;
} */