@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}
.login_section {
  background-image: url("../src/assets/img/login_bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #36454f; */
}

.content {
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.login_label_text {
  color: #ffffff;
}

.login_formcontrol {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
  margin-top: 5px;
}

.login_formcontrol::placeholder {
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .content {
    width: 70%;
    max-width: 500px;
  }
}

.headingfour {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  color: #736f6f;
}
.headingfive {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: #736f6f;
}

.paragraph_text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: #c8c8c8;
}
@media (min-width: 320px) and (max-width: 900px) {
  #form-main-container {
    width: 100% !important;
  }
}

.sidebar_heading {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
}

.form-check-input[type="radio"] {
  font-size: 18px !important;
  cursor: pointer !important;
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: unset !important;
  box-shadow: none !important;
}
.accordion-button {
  color: #fff !important;
  background-color: unset !important;
  box-shadow: none !important;
}

.copyright-heading a {
  text-decoration: underline !important;
  color: #008080 !important;
}

.pending_text {
  color: #f56e02;
}
.inputfile {
  display: none;
}
.upload-button {
  cursor: pointer;
  transition: background-color 0.3s;
}
.primary-btn {
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  width: 100px;
  padding: 10px;
  background: var(--btn-bg-color);
  border-radius: 4px;
  border: 1px solid var(--btn-border-color);
  font-style: normal;
  font-weight: var(--font-weight-500);
  font-size: var(--btn-text-fontsize);
  line-height: var(--line-height-20);
  letter-spacing: 0.1px;
  color: var(--btn-text-color);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--primary-btn-text);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.sales_team {
  color: #008080;
  text-decoration: underline;
}

.active-links a {
  text-decoration: underline !important;
  color: #008080 !important;
}
.active-links span {
  cursor: pointer;
  text-decoration: underline;
  color: #008080;
}

table th {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-style: normal;
}
table td {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #1f1f1f;
  font-style: normal;
}

.form-select {
  font-weight: 500px !important;
  font-size: 14px !important;
  color: #636363 !important;
}
.form-control {
  font-weight: 500px !important;
  font-size: 14px !important;
}
textarea {
  font-weight: 500px !important;
  font-size: 14px !important;
  color: #636363 !important;
}
.inputfield.form-control {
  font-weight: 500px !important;
  font-size: 14px !important;
  background-color: #eeeeee;
  border: #eeeeee;
}
.outline_btn {
  background-color: transparent;
  border: 1px solid red;
  padding: 5px 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.outline_btn:hover {
  border-color: blue;
}

.pagenation_btn {
  border: none;
}

.pagination_btn {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a9a9a9;
  font-weight: 600;
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.pagination_btn.active {
  background-color: #008080;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.prev_button {
  width: 50px;
}

.next_button {
  width: 50px;
}
.pagination_btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
hr {
  color: #808080 !important;
}

.date-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  padding: 8px;
  background-color: red;
}

.sidebar-open .mui-sidebar {
  width: 0;
}

.sidebar-open .mui-content {
  margin-left: 0;
}

label {
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  font-family: "Inter", sans-serif !important;
}
.form-label {
  font-weight: 600px !important;
  font-family: "Inter", sans-serif !important;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050 !important;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: #000 !important;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (max-width: 768px) {
  #form-main-container {
    width: 100% !important;
  }
  .overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}

.deletebody .list-group-item {
  border: none !important;
}
.loginpage .login_lable {
  font-family: "Montserrat", sans-serif !important;
}

.button_width {
  width: 50%;
}

.custom-modal-xl {
  max-width: 60%;
}

.close_icon {
  position: absolute;
  right: 20px;
}

.custom-modal-xl .modal-header {
  background: rgba(88, 111, 128, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: relative;
}

.custom-modal-xl .modal-body {
  border-radius: 10px !important;
}

.custom-modal-xl .view_header {
  font-size: 16px;
}

.custom-modal-xl.close_icon {
  cursor: pointer;
}
.download_icon {
  cursor: pointer;
}

.disable-state {
  color: #9b9b9b;
}

textarea:disabled,
select:disabled,
input:disabled {
  cursor: not-allowed;
}
.selected_files_list {
  max-height: 300px;
  overflow: auto;
}

.selected-file {
  background-color: #f0f0f0; /* Background color */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.selected-file span {
  flex-grow: 1;
  font-size: 14px;
  white-space: nowrap;
  color: #000;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close_list_icon {
  cursor: pointer;
}

.addpayment_subheading {
  font-weight: 500;
  color: #636363;
  font-size: 14px;
}
.addpayment_subheading span {
  font-weight: 400;
}
.dashboardcard {
  font-size: large;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 2px 3px 20px 0px lightgrey;
  cursor: pointer;
  color: #3c4c57;
  font-family: "Montserrat";
  font-weight: 500;
  max-height: 120px;
}
.dashboardcard:hover {
  color: #fff;
  background-color: #586f80;
}

.dashboardcard:hover img {
  filter: none;
}
.dashboardcard img {
  filter: brightness(0) saturate(100%) invert(27%) sepia(30%) saturate(324%)
    hue-rotate(161deg) brightness(95%) contrast(96%);
}
